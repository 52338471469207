import React from 'react';
import './ButtonWhite.css';

const ButtonWhite = (props) => {
  return (
    <div className='button_white' style={{
      ...props?.style,
      width: props?.width,
      border: props?.disabled ? '1.2px solid #999999' : 
             props?.borderNone ? 'none' : undefined
    }}>
      <button 
        disabled={props?.disabled} 
        style={{cursor: props?.pointer, color: 'var(--text-color)'}} 
        onClick={props.onClick} 
        className={`button_main ${props.isSelected ? 'highlight' : ''}`}
      >
        {props.name}
      </button>
    </div>
  )
}

export default ButtonWhite;
