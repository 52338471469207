import React from 'react';
import './Button.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Button = (props) => {
  console.log(props,'props')
  return (
    <div className='button' style={{
      width: props?.btnWidth,
      marginBottom: props?.marginBottom || '0'
    }}>
      <button disabled={props?.disabled || props?.isLoading} onClick={props.onClick} className='button_main'>
        {props.loader ? 
        // <div className='loader d-flex align-items-center gap-2'>
        //   <span>Sign In</span>
        //   <Spin indicator={<LoadingOutlined spin />} size="large" />
        // </div>
        'Signing in...'
        : props.name}
        </button>
    </div>
  )
}

export default Button;
