// import { toast } from 'react-toastify';
import { getDocumentData } from '../Documents/documentsAction';
import * as ActionType from './CommonActionType';
export const handlePagination = (start, end, total, key) => (dispatch) => {
  const queueName = sessionStorage.getItem('queuename');
  try {
    dispatch(getDocumentData());
    dispatch({
      type: ActionType.PAGINATION,
      payload: { start, end, total },
    });
  } catch (error) {
    console.log(error);
  }
};

export const redirectToLogin = () => {
  // toast.error('Session expired, please login again');
  setTimeout(() => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace('/');
  }, 2000);
  // Set a flag to indicate logout is in progress
  //localStorage.setItem('isLoggingOut', 'true');
};
export const redirectToProcessorDashboard = () => {
  setTimeout(() => {
    window.location.replace('/');
  }, 1000);
};
export const handleEditing = (payload) => dispatch => {
  dispatch({
    type: ActionType.IS_EDITING,
    payload,
  });
}
