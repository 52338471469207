import React from 'react';
import './404Error.css';
import Button from '../../Components/Button/Button';
import ButtonWhite from '../../Components/ButtonWhite/ButtonWhite';
import Error404Image from '../../Images/404.png';

const Error404 = () => {   
    const logout = () => {
      sessionStorage.clear();
      window.location.href = '/';
    };
    const goBack =()=>{
      window.history.back();
    }
  return (
    <div className="not-found-container">
        <img className="not-found-image" src={Error404Image} alt="Page Not Found" />
        <h1 className="not-found-title">
        Page Not found! (404)
        </h1>
        <p className="not-found-text">
        The page you're looking for can't be found. It may have been moved or <br />
        deleted. Please check the URL or return to the homepage.
        </p>
        <div className="not-found-buttons d-flex justify-content-center">
        <ButtonWhite width="fit-content" onClick={logout} name="Go to Login" />
        <Button btnWidth="fit-content" onClick={goBack} name="Go Back" />
        </div>
    </div>
  )
};

export default Error404
