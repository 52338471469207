import React, { createContext, useEffect, useState } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
// import { useSnackbar } from "notistack";
import toast from "react-hot-toast";
import { WEBSOCKET_URL } from "../Constants";

export const Context = createContext();

const ContextProvider = (props) => {
  // const { enqueueSnackbar } = useSnackbar();
  const [client, setClient] = useState(null);
  const [response, setResponse] = useState(""); // State to store the response

  //   Examples

  const [input, setInput] = useState("");
  const [recentPrompt, setRecentPrompt] = useState("");
  const [prevPrompts, setPrevPrompts] = useState([]);
  const [allPrompts, setAllPrompts] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState("");
  const [resultData, setResultData] = useState("");
  const [currentPromptIndex, setCurrentPromptIndex] = useState(-1);
  const [showPrevPrompts, setShowPrevPrompts] = useState(false);
  const [displayedPrompts, setDisplayedPrompts] = useState([]); // State for the displayed recent prompt
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [isModalFeedbackVisible, setIsModalFeedbackVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [socket, setSocket] = useState(null);
  const [docid, setDocid] = useState("");
  const e_id = sessionStorage.getItem("e_id");
  const user = sessionStorage.getItem("name");
  const [queueId, setQueueId] = useState(sessionStorage.getItem("AnalyticsQ_Id") || "");
  const [processorId, setProcessorId] = useState(sessionStorage.getItem("AnalyticsP_Id") || "");
  const [dataLoading, setDataLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [finalValidation, setFinalValidation] = useState([]);
  const [dateOpen, setDateOpen] = useState(false);
  
  const finalInvs = {
    q_id: queueId,
    e_id,
    user
  };
  const stringifiedfinalInvs = JSON.stringify(finalInvs);
  console.log("stringifiedfinalInvs", stringifiedfinalInvs);

  useEffect(() => {
    console.log('into context use effect')
  }, [])
  const showModal = () => {
    setIsModalFeedbackVisible(true);
  };
  
  useEffect(() => {
    const blurContainer = document.querySelector("#mainContent");
    
    // Check if blurContainer exists before trying to access classList
    if (blurContainer) {
      if (filterOpen || dateOpen) {
        blurContainer.classList.add("blur-background");
      } else {
        blurContainer.classList.remove("blur-background");
      }
      // Clean up function should also check if element exists
      return () => blurContainer.classList.remove("blur-background");
    }
    // Return empty cleanup function if element doesn't exist
    return () => {};
  }, [filterOpen, dateOpen]);

  // console.log({ docid });

  const handleOk = () => {
    if (!comment.trim()) {
      toast.error("Comment cannot be empty. Please enter your feedback.");
      setComment("");
      return;
    }
    console.log(docid, 'into handle ok')

    // if (!resultData.response) {
    //   toast.error("Please select or ask a question to provide feedback");
    //   setComment("");
    //   return;
    // }

    // if (!docid) {
    //   toast.error("Please select or ask a question to provide feedback");
    //   setComment("");
    //   return;
    // }

    if (socket && socket.readyState === WebSocket.OPEN) {
      // Send the feedback comment to the WebSocket server
      // Send only the feedback comment to the WebSocket server
      socket.send(comment);

      // Listen for the WebSocket response
      socket.onmessage = (event) => {
        const response = event.data;

        toast.success(response.response || "Feedback submitted successfully!");
      };

      // Close the modal and reset the comment
      setIsModalFeedbackVisible(false);
      setComment("");
    } else {
      toast.error("Failed to connect to the server. Please try again later.");
    }
  };

  const handleCancelFeedback = () => {
    setIsModalFeedbackVisible(false);
  };

  useEffect(() => {
    // Define platform and docId parameters
    // const platformData = { platform: getPlatform };
    // const docId = docid;

    // Construct WebSocket URL
    // const platformString = encodeURIComponent(JSON.stringify(platformData));
    //const wsUrl = `wss://www.ask.intainabs.com/cfida/feedback/${platformString}?docId=${docId}`;
    const wsUrl = `${WEBSOCKET_URL}feedback/${stringifiedfinalInvs}`;
    // Initialize WebSocket connection
    const ws = new WebSocket(wsUrl);
    setSocket(ws);

    // Clean up WebSocket connection when component unmounts
    return () => {
      if (ws) ws.close();
    };
  }, [queueId]);

  useEffect(() => {
    let pingInterval;
    console.log('into websocket use effect')
    try {
      // const newClient = new W3CWebSocket(
      //   `wss://www.ask.intainabs.com/cfida/${stringifiedfinalInvs}`
      // );
      console.log(stringifiedfinalInvs,'into websocket use effect')
      const newClient = new W3CWebSocket(
        `${WEBSOCKET_URL}converse_doc/${stringifiedfinalInvs}`
      );

      newClient.onopen = () => {
        console.log("WebSocket Client Connected");

        // Start sending ping messages every 30 seconds (or adjust as needed)
        pingInterval = setInterval(() => {
          if (newClient.readyState === WebSocket.OPEN) {
            // newClient.send(JSON.stringify({ type: "ping" })); // Send a ping message
            console.log("Ping sent to keep the connection alive.");
          }
        }, 1200000); // 30 seconds interval
      };

      newClient.onmessage = (message) => {
        // console.log("Received:", message.data);
        setResultData(message.data); // Set the response in the state

        setLoading(false);
      };

      newClient.onerror = (error) => {
        console.error("WebSocket Error:", error);
      };

      newClient.onclose = () => {
        console.log("WebSocket Client Closed");
        clearInterval(pingInterval); // Stop pinging when the connection is closed
      };

      setClient(newClient);
    } catch (error) {
      console.error("Failed to create WebSocket connection:", error);
    }

    return () => {
      if (client) {
        client.close(); // Clean up when the component unmounts
        clearInterval(pingInterval); // Stop pinging when component unmounts
      }
    };
  }, [queueId]);

  // Parse resultData and extract docid
  useEffect(() => {
    if (resultData) {
      try {
        const parsedData = JSON.parse(resultData);
        // console.log({ parsedData });

        setDocid(parsedData.docId || ""); // Set docid if available
      } catch (error) {
        console.error("Failed to parse resultData:", error);
      }
    }
  }, [resultData]);

  // const handleSaveClick = () => {
  //   const finalPrompt = recentPrompt; // Get the current recent prompt
  //   if (finalPrompt.trim() !== "") {
  //     savePromptToHistory(finalPrompt); // Save prompt to history
  //     setShowPrevPrompts(true); // Show previous prompts
  //     console.log("Saved prompt to history:", finalPrompt); // Optional logging
  //   }
  // };

  // Function to handle clicking the save button
  const handleSaveClick = () => {
    // Check if recentPrompt is not empty and not already in displayedPrompts
    if (
      recentPrompt.trim() !== "" &&
      !displayedPrompts.includes(recentPrompt.trim())
    ) {
      setDisplayedPrompts((prev) => [...prev, recentPrompt.trim()]);
      setSelectedPrompt(recentPrompt);
      setShowPrevPrompts(true); // Show the prompt
      // console.log("Saved prompt to history:", recentPrompt); // Optional logging
    }
  };

  const loadPrompt = async (prompt, index) => {
    setRecentPrompt(prompt);
    setSelectedPrompt(prompt);
    setCurrentPromptIndex(index); // Set the correct index
    await onSent(prompt);
  };

  const onSent = async (prompt) => {
    if (client && client.readyState === WebSocket.OPEN) {
      setResultData("");
      setLoading(true);
      setShowResult(true);

      let finalPrompt = prompt !== undefined ? prompt : input;

      // Ensure prompt is not empty
      if (finalPrompt.trim() !== "") {
        setPrevPrompts((prev) => {
          if (!prev.includes(prompt)) {
            const updatedPrompts = [...prev, prompt];
            setCurrentPromptIndex(updatedPrompts.length - 1); // Update index after adding prompt
            return updatedPrompts;
          }
          return prev; // Return the same array if it already exists in history
        });
        // Send the prompt to the server
        client.send(JSON.stringify(`${finalPrompt}`));
        setRecentPrompt(finalPrompt);
        setInput(""); // Clear the input
        // console.log("Question sent:", finalPrompt);
      } else {
        console.log("Prompt is empty or undefined.");
      }
    } else {
      console.log("WebSocket is not connected.");
    }
  };

  // const savePromptToHistory = (prompt) => {
  //   setPrevPrompts((prev) => {
  //     if (!prev.includes(prompt)) {
  //       const updatedPrompts = [...prev, prompt];
  //       setCurrentPromptIndex(updatedPrompts.length - 1); // Update index after adding prompt
  //       return updatedPrompts;
  //     }
  //     return prev; // Return the same array if it already exists in history
  //   });
  // };

  const contextValue = {
    prevPrompts,
    setPrevPrompts,
    onSent,
    setRecentPrompt,
    setCurrentPromptIndex,
    currentPromptIndex,
    recentPrompt,
    showResult,
    loading,
    resultData,
    input,
    showPrevPrompts,
    selectedPrompt,
    isModalFeedbackVisible,
    comment,
    setComment,
    handleOk,
    handleCancelFeedback,
    showModal,
    setIsModalFeedbackVisible,
    setSelectedPrompt,
    setInput,
    setShowResult,
    handleSaveClick,
    setDocid,
    displayedPrompts,
    setDisplayedPrompts,
    loadPrompt,
    // theme, 
    // toggleTheme
    setQueueId,
    queueId,
    setProcessorId,
    processorId,
    dataLoading,
    setDataLoading,
    filterOpen,
    setFilterOpen,
    finalValidation,
    setFinalValidation,
    dateOpen,
    setDateOpen
  };

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
};

export default ContextProvider;
