import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import _store from './Store';
import ContextProvider from './Context/Context';

const { persistor, Store } = _store();

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
  // production code
  console.log = () => { };
} else {
  // dev code
  // console.log = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <PersistGate persistor={persistor}>
    <Provider store={Store}>
      <ContextProvider>
        <App/>
      </ContextProvider>
    </Provider>
  </PersistGate>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
