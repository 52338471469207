import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useHistory } from "react-router-dom";
import CustomLayout from "../Layouts/CustomLayout";
import ProcessorLayout from "../Layouts/ProcessorLayout";
import Loader from "../Components/Loader/Loader";
import { Toaster } from 'react-hot-toast';
import Error404 from "../Containers/404Erorr/404Erorr";

const LazyLogin = React.lazy(() => import("../Containers/Login/Login"));
const LazySignup = React.lazy(() => import("../Containers/Signup/Signup"));
const LazyActivateProcessor = React.lazy(() => import("../Containers/ActivateProcessor/ActivateProcessor"));
// admin
const LazyQueues = React.lazy(() => import("../Containers/Queues/Queues"));
const LazyQueueMapProcessor = React.lazy(() => import("../Containers/QueuesMapProcessors/QueuesMapProcessors"));
const LazyDataCheck = React.lazy(() => import("../Components/DataCheck/DataCheck"));
const LazyProcessor = React.lazy(() => import("../Containers/Processor/Processor"));
const LazyDocument = React.lazy(() => import("../Containers/Documents/Documents"));
const LazyFields = React.lazy(() => import("../Containers/Fields/Fields"));
const LazyAdminUploads = React.lazy(() => import("../Containers/AdminUpload/AdminUpload"));
const LazyAnalytics = React.lazy(() => import("../Containers/Analytics/Analytics"));

// processor
const LazyProcessorDashboard = React.lazy(() => import("../ProcessorContainer/ProcessorDashboard/ProcessorDashboard"));
const LazyBatchFolderList = React.lazy(() => import("../ProcessorContainer/BatchFolders/BatchFolders"));
const LazyEditDocument = React.lazy(() => import("../ProcessorContainer/EditDocuments/EditDocument"));
const LazyDigitizeView = React.lazy(() => import("../Containers/Digitizeview/Digitizeview"));
const LazyProcessorAnalytics = React.lazy(() => import("../Containers/Analytics/ProcessorDashboardAnalytics"));

function HomeRoutes() {
    const [flagType, setFlagType] = useState(false);
    useEffect(() => {
      if (sessionStorage.getItem('type') === 'admin') {
        setFlagType('admin');
      } else if (sessionStorage.getItem('type') === 'processor') {
        setFlagType('processor');
      } else {
        setFlagType('client');
      }
    }, [sessionStorage.getItem('type')]);

    return(
        <Routes>
            { flagType === 'admin' && (
                <>
                    <Route path="/" element={<CustomLayout module="admin" />}>
                        <Route index element={<LazyQueues />} />
                        <Route path="/queuemapprocessor" element={<LazyQueueMapProcessor />} />
                        <Route path="/datacheck" element={<LazyDataCheck />} />
                        <Route path="/processor" element={<LazyProcessor />} />
                        <Route path="/document" element={<LazyDocument />} />
                        <Route path="/field" element={<LazyFields />} />
                        <Route path="/adminupload" element={<LazyAdminUploads />} />
                        <Route path="/analytics" element={<LazyAnalytics />} />
                    </Route>
                    <Route path="*" element={<Error404 />} />
                </>
            )}
            { flagType === 'processor' && (
                <>
                    <Route path="/" element={<ProcessorLayout/>}>
                        <Route index element={<LazyProcessorDashboard />} />
                        <Route path="/folderslist" element={<LazyBatchFolderList />} />
                        <Route path="/editdocument" element={<LazyEditDocument />} />
                        <Route path="/digitizeview" element={<LazyDigitizeView />} />
                        <Route path="/analytics" element={<LazyProcessorAnalytics />} />
                    </Route>
                    <Route path="*" element={<Error404 />} />
                </>
            )}
        </Routes>
    )
}

function AuthRoutes() {
    return(
        <Routes>
            <Route path='/' element={<LazyLogin/>} />
            <Route path='/signup' element={<LazySignup/>} />
            <Route path='/register' element={<LazyActivateProcessor/>} />
        </Routes>
    )
}

const RootRouter = () =>{
    const token = sessionStorage.getItem('token');
    const Authenticated = token;
    console.log('into router')
    return(
        <>
            <Toaster 
                position="top-right" 
                reverseOrder={true} 
                toastOptions={{
                    success: {
                        iconTheme: {
                          primary: "#00403A", // Icon color for success (e.g., green)
                          secondary: "#FFF", // Icon background color for success (e.g., white)
                        },
                    },
                    error: {
                        // Default styles for error messages
                    },
                  }}
            />
            <Suspense fallback={<Loader/>}>
                <Router>
                    {Authenticated && sessionStorage.getItem('type') ? <HomeRoutes/> : <AuthRoutes/>}
                </Router>
            </Suspense>
        </>
    )
};

export default RootRouter;