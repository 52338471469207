import React, { useEffect } from 'react';
import './App.css';
import RootRouter from './Routers/RootRouter.js';
import toast from 'react-hot-toast';

function App() {
  useEffect(() => {
    const tabId = sessionStorage.getItem("tabId");

    if (tabId) {
      const parts = tabId.split("-");
      const sessionStart = parseInt(parts[1], 10);

      if (!isNaN(sessionStart)) {
        const targetTime = sessionStart + 50 * 60 * 1000; // 50 minutes
        const now = Date.now();
        const delay = targetTime - now;
        console.log(targetTime, now, delay,'qwertyuioiuhgvbhj');
        if (delay > 0) {
          setTimeout(() => {
            toast.error("Try to save your work.");
          }, delay);
        } else {
          toast.error("Try to save your work.");
        }
      } else {
        console.error("❌ Could not parse session start time from tabId.");
      }
    } else {
      console.error("❌ tabId not found in sessionStorage.");
    }
  }, []);

  return (
    <div className="App">
      <RootRouter/>
    </div>
  );
}

export default App;
